import { RecursivePartial } from '@models/base';
import { IntegrationSetup } from '@models/integrations';
import { BaseItemsState } from './base-items-state';

export class IntegrationsState extends BaseItemsState<IntegrationSetup> {

    constructor(json?: RecursivePartial<IntegrationsState>) {
        super(json);
        this.parseIntegrationsState(json || {});
    }

    parse(json: RecursivePartial<IntegrationsState>): IntegrationsState {
        super.parse(json);
        this.parseIntegrationsState(json);
        return this;
    }

    private parseIntegrationsState(json: RecursivePartial<IntegrationsState>): void {
        IntegrationsState.assignClassMaps<IntegrationsState>(this, json, { items: IntegrationSetup });
    }

}
