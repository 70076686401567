import { Guid, SM } from "./helper-types";

export const SYSTEM_MODULE_IDS = ['tasks', 'chats', 'events'] as const;
export type VzSystemModule = typeof SYSTEM_MODULE_IDS[number];
export const SYSTEM_MODULE_NAMES = {
    'tasks': 'Задачи',
    'chats': 'Чаты',
    'events': 'Календарь'
}
export function getDefaultSystemModuleParams(type: VzSystemModule, id: Guid): SM<any> {
    if (type == 'tasks') {
        return { projectId: id };
    }
    else if (type == 'chats') {
        return { chatId: id };
    }
    else {
        return {};
    }

}