import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of, share, Subject, Subscription } from 'rxjs';

import { BaseLogging } from '@base/base-logging';
import {
    Tag, SM, PrefsState, Person, AppLogState, NewTaskContent, OrgInfo, PREFS_DEFAULTS,
    StatesType, StateKeyType, StatesMapType, StateCacheKeyType, StateKeys,
    StateCacheMapsType, StateFilterType, StateCacheArrayType, StateCacheItemType, StateCacheType,
    newStateInstance, FilterType, AccessTokenState, cutString, setGrantedPrivileges,
    getAllUserPrivileges
} from '@models';
import { VzCrashData } from '@models/shared/crash-data';
import { JsonSafePipe } from '@pipes';
import { environment } from '@env/environment';

const TEMP_CACHE_TTL = 60000;
type TGetTempObject<T> = { fn: (id: string) => Observable<T>, prefix?: string, ttl?: number };

const StateMigrations: { [T in StateKeyType]?: { [key: string]: (json: any) => Partial<StatesType[T]> } } = {
    // possible state migrations
    'prefs': {
        '1->*': migratePrefs_1toAny,
        '*->11': migratePrefs_AnyTo11,
    }
};

function migratePrefs_1toAny(json: any): Partial<PrefsState> {
    if (json.tablesResizePolicy !== undefined && !json.tableResizePolicy) {
        json.tableResizePolicy = json.tablesResizePolicy >= 0 && json.tablesResizePolicy < 3 ? ['simple', 'flex', 'msword'][json.tablesResizePolicy] : 'msword';
    }
    return json;
}

function migratePrefs_AnyTo11(json: any): Partial<PrefsState> {
    if (json._VER < 5) {
        json.editorToolbarShow = { _: true };
        json.editorSendByEnter = { _: true, newTask: false, taskAddNote: false, taskTextEdit: false, chatAddComment: true };
    }
    const lts: string[] = ['inbox', 'favorites', 'inprogress', 'outgoing', 'closed'];
    if (json._VER < 6) {
        lts.push('all', 'support');
    }
    if (json._VER < 10) {
        for (const lt of lts) {
            json[lt + 'TasksColsOrder'] = (PREFS_DEFAULTS as any)[lt + 'TasksColsOrder'];
            json[lt + 'TasksColsWidth'] = (PREFS_DEFAULTS as any)[lt + 'TasksColsWidth'];
            json[lt + 'TasksColsVis'] = (PREFS_DEFAULTS as any)[lt + 'TasksColsVis'];
            json[lt + 'TasksSort'] = (PREFS_DEFAULTS as any)[lt + 'TasksSort'];
        }
    }
    if (json._VER < 11) {
        if (json.lsmShow && json.lsmShow.telegram === undefined) {
            json.lsmShow.telegram = true;
        }
    }
    return json;
}

@Injectable({ providedIn: 'root' })
@Tag('StoreService')
export class StoreService extends BaseLogging {

    static STORAGE_VER = '2';
    STORAGE_KEY = environment.admin ? '@@VZADMIN' : '@@VIZORRO';

    readonly authTokenState: BehaviorSubject<AccessTokenState> = new BehaviorSubject<AccessTokenState>(AccessTokenState.Unknown);
    readonly activeOrgId: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);
    readonly appLog: AppLogState = new AppLogState();
    readonly newTaskContent: BehaviorSubject<NewTaskContent | undefined> = new BehaviorSubject<NewTaskContent | undefined>(undefined);
    readonly taskExtras: BehaviorSubject<{ taskId: string, files?: File[], target: 'task' | 'note' } | undefined> = new BehaviorSubject<{ taskId: string, files?: File[], target: 'task' | 'note' } | undefined>(undefined);
    readonly syncLost: Subject<void> = new Subject();
    readonly wakeUp: Subject<void> = new Subject();
    readonly orgLimits: BehaviorSubject<SM<OrgInfo>> = new BehaviorSubject({});
    readonly crashReport: Subject<VzCrashData> = new Subject();
    readonly showRN: Subject<void> = new Subject();
    readonly showOrgMenu: Subject<void> = new Subject();
    readonly fcmToken: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);
    readonly onLogout: Subject<{ title: string, text: string, data: any }> = new Subject();

    apiError?: any;
    apiErrorTitle?: string;

    private _s!: Storage;
    private _states!: StatesMapType;
    private _jsp = new JsonSafePipe();

    public readonly tempCache: SM<any> = {};
    public readonly tempCacheSubscriptions: SM<Observable<any>> = {};
    private tempCacheTimes: SM<number> = {};
    public mobile?: boolean;
    public userId?: string;
    public user?: Person;
    readonly granted: BehaviorSubject<SM<SM<boolean>>> = new BehaviorSubject<SM<SM<boolean>>>({ global: {}, _notReady: {} });
    public readonly invalidateCache: Subject<'all' | StateCacheKeyType> = new Subject();
    private _lastActivity: number = Date.now();

    constructor() {
        super();
        this._s = sessionStorage.getItem(this.STORAGE_KEY) ? sessionStorage : localStorage;
        const ver = this._s.getItem(this.STORAGE_KEY);
        if (!ver) {
            this._L('constructor, clear storages');
            localStorage.clear();
            sessionStorage.clear();
            this._s.setItem(this.STORAGE_KEY, StoreService.STORAGE_VER);
        }
        else if (ver == '1') {
            this.deleteItem('log' as any);
            this._s.setItem(this.STORAGE_KEY, StoreService.STORAGE_VER);
        }
        this._states = {} as any;
        StateKeys.forEach(key => {
            const json = this.getItem(key, {});
            const state = this.migrateState(key, json);
            this.setItem(key, state);
            this._L('constructor', 'intial state:', state);
            this._states[key] = new BehaviorSubject(state as any);
        });
        this._states.user.subscribe(state => {
            this.mobile = state.mobile || !this.__WEB;
            this.userId = state.userId;
            this.user = state.user;
        });
        // if (this.__ENV.env == 'prod') {
            this.__setLogFunctionsCb((severity, tag, ...x) => this.storeLog(severity, tag, ...x));
        // }
        this.updateGrantedPrivs();
        setInterval(() => this.invalidateTempCacheObjects(), TEMP_CACHE_TTL);
    }

    private storeLog(severity: 'log' | 'warn' | 'error', tag: string, ...x: any[]): void {
        console[severity](`[${tag}]`, ...x);
        this.appLog.addItem({
            dt: Date.now() / 1000,
            severity,
            tag,
            items: x.map(xi => cutString(typeof xi == 'object' ? this._jsp.transform(xi, 0) : xi, 1000))
        });
    }

    migrateState<T extends StateKeyType>(key: T, json: any): StatesType[T] {
        const state = newStateInstance[key]({});
        if (json && state._VER !== json._VER && StateMigrations[key]) {
            if (StateMigrations[key]![json._VER + '->' + state._VER]) {
                this._L('migrateState', `migrate state "${key}" ${json._VER + '->' + state._VER}`);
                json = StateMigrations[key]![json._VER + '->' + state._VER](json);
            }
            else if (StateMigrations[key]![json._VER + '->*']) {
                this._L('migrateState', `migrate state "${key}" ${json._VER + '->' + state._VER}`);
                json = StateMigrations[key]![json._VER + '->*'](json);
            }
            else if (StateMigrations[key]!['*->' + state._VER] && state._VER > json._VER) {
                this._L('migrateState', `migrate state "${key}" ${'*->' + state._VER}`);
                json = StateMigrations[key]!['*->' + state._VER](json);
            }
        }
        state.parse(json);
        return state;
    }

    setActiveOrg(id?: string): void {
        this._W('setActiveOrg', 'current:', this.activeOrgId.getValue(), 'new:', id);
        this.activeOrgId.next(id);
        this.updateGrantedPrivs();
    }

    setStorageType(local = true): void {
        this._s = local ? localStorage : sessionStorage;
    }

    state<T extends keyof StatesMapType>(name: T): StatesMapType[T] {
        return this._states[name];
    }

    getStateDirect<T extends StateKeyType>(name: T): StatesType[T] {
        return this._states[name].getValue();
    }

    getState<T extends StateKeyType>(name: T): StatesType[T];
    getState<T extends StateKeyType, K>(name: T, filter: FilterType<T, K>): K;
    getState<T extends StateKeyType, K, N extends undefined | FilterType<T, K>>(name: T, filter?: N): StatesType[T] | K {
        // const value: any = this._states[name].getValue();
        // return filter
        //     ? filter(value.newInstance(value) as StatesType[T])
        //     : value.newInstance(value) as StatesType[T];
        return filter ? filter(this._states[name].getValue()) : this._states[name].getValue();
    }

    setState<T extends StateKeyType>(name: T, value?: StatesType[T]): StatesType[T] {
        // this._L(`setState[${name}]:`, value);
        if (name == 'prefs') {
            if (!(value as any)?.defaultOrgId) {
                this._W(`setState[${name}]`, 'NO defaultOrgId!!!');
            }
            this._L(`setState[${name}]:`, value);
        }
        const newValue: any =  newStateInstance[name](value);
        this.state(name).next(newValue as any);
        this.setItem(name, newValue);
        return newValue;
    }

    patchState<T extends StateKeyType>(name: T, patch: Partial<StatesType[T]>): StatesType[T] {
        const value = this.state(name).getValue();
        const newValue: any = newStateInstance[name](value);
        Object.keys(patch).forEach(k => delete newValue[k]);
        newValue.parse(patch as any);
        if (name == 'prefs') {
            if (!(newValue as any)?.defaultOrgId) {
                this._W(`patchState[${name}]`, 'NO defaultOrgId!!!б old:', (value as any)?.defaultOrgId, 'new:', (newValue as any)?.defaultOrgId);
            }
            this._L(`patchState[${name}]:`, patch, newValue);
        }
        this.state(name).next(newValue);
        this.setItem(name, newValue);
        return newValue;
    }

    clearAllStates(): void {
        this._clearAllStates(['url']);
    }

    clearAllStatesExcept<T extends StateKeyType>(exceptions?: T[]): void {
        this._clearAllStates(exceptions);
    }

    fillWithStates<T extends StateCacheKeyType>(stores: T[], maps?: StateCacheMapsType, filter?: StateFilterType): StateCacheArrayType {
        const m: StateCacheArrayType = {};
        for (const sk of stores) {
            const state: any = this._states[sk].getValue();
            if (filter) {
                if (maps) {
                    maps[sk] = {};
                }
                m[sk] = [];
                if (state.items) {
                    for (const id of Object.keys(state.items)) {
                        if (!filter[sk] || filter[sk]!(state.items[id])) {
                            if (maps) {
                                (maps[sk] as any)[id] = state.items[id];
                            }
                            m[sk]?.push(state.items[id]);
                        }
                    }
                }
            }
            else {
                if (maps) {
                    maps[sk] = state.items || {};
                }
                m[sk] = Object.values(state.items) as any;
            }
        }
        return m;
    }

    subscribeToStates<T extends StateCacheKeyType>(
        subscriptions: { [id: string]: Subscription },
        stores: { [T in StateCacheKeyType]?: (st: StateCacheType[T]) => void },
        maps?: StateCacheMapsType,
        filter?: StateFilterType
    ): void {
        for (const [sk, func] of Object.entries(stores)) {
            const name: T = sk as any;
            const state = this.state(name) as Observable<any>;
            subscriptions['__@@' + sk] = state.subscribe(stt => {
                if (filter) {
                    const items: { [id: string]: StateCacheItemType[T] } = {};
                    if (stt.items) {
                        for (const id of Object.keys(stt.items)) {
                            if (!filter[name] || filter[name]!(stt.items[id])) {
                                items[id] = stt.items[id];
                            }
                        }
                    }
                    if (maps) {
                        maps[name] = items as any;
                    }
                    func(newStateInstance[name]({ items }) as any);
                }
                else {
                    if (maps) {
                        maps[name] = stt.items;
                    }
                    func!(stt);
                }
            });
        }
    }

    private _clearAllStates<T extends StateKeyType>(exceptions?: T[]): void {
        Object.keys(this._states).forEach(key => {
            const name: T = key as any;
            if (!exceptions || exceptions.indexOf(name) == -1) {
                const newValue: any =  newStateInstance[name]({});
                this.state(name).next(newValue as any);
                this.deleteItem(name as any);
            }
        });
    }

    getItem(key: string, defValue?: any): any {
        const value = this._s.getItem(this.STORAGE_KEY + '_' + key);
        let res = defValue;
        if (value !== null) {
            try {
                res = JSON.parse(value);
            }
            catch {
                res = defValue;
            }
        }
        return res;
    }

    setItem(key: string, value: any): void {
        this._s.setItem(this.STORAGE_KEY + '_' + key, JSON.stringify(value));
    }

    deleteItem(key: StateKeyType): void {
        this._s.removeItem(this.STORAGE_KEY + '_' + key);
    }

    clearTempCache(): void {
        Object.keys(this.tempCache).forEach(id => delete this.tempCache[id]);
        this.tempCacheTimes = {};
    }

    private invalidateTempCacheObjects(): void {
        const ndt = new Date().getTime();
        for (const id of Object.keys(this.tempCacheTimes)) {
            if (ndt - TEMP_CACHE_TTL > this.tempCacheTimes[id]) {
                delete this.tempCache[id];
                delete this.tempCacheTimes[id];
            }
        }
    }

    removeTempObject<T>(id: string): T | undefined {
        const obj = this.tempCache[id];
        delete this.tempCache[id];
        return obj;
    }

    putTempObject(cacheId: string, item: any, ttl: number): void {
        this.tempCache[cacheId] = item;
        this.tempCacheTimes[cacheId] = new Date().getTime() + ttl;
    }

    getTempObject<T>(id: string, prefix?: string): T | undefined;
    getTempObject<T>(id: string, opts: TGetTempObject<T>): Observable<T>;
    getTempObject<T>(id: string, prefixOrOpts?: string | TGetTempObject<T>): T | Observable<T> | undefined {
        if (typeof prefixOrOpts != 'string' && prefixOrOpts?.fn) {
            const fn = prefixOrOpts.fn;
            const cacheId = (prefixOrOpts?.prefix ? prefixOrOpts.prefix : '') + id;
            const ttl = TEMP_CACHE_TTL - (prefixOrOpts?.ttl || 60) * 1000;
            if (this.tempCache[cacheId]) {
                return of<T>(this.tempCache[cacheId]);
            }
            else if (this.tempCacheSubscriptions[cacheId]) {
                return this.tempCacheSubscriptions[cacheId] as Observable<T>;
            }
            else {
                this.tempCacheSubscriptions[cacheId] = new Observable<T>(obs => {
                    fn(id).subscribe({
                        next: item => {
                            if (item) {
                                this.putTempObject(cacheId, item, ttl);
                            }
                            obs.next(item);
                        },
                        error: err => obs.error(err),
                        complete: () => {
                            obs.complete();
                            delete this.tempCacheSubscriptions[cacheId];
                        }
                    })
                }).pipe(share());
                return this.tempCacheSubscriptions[cacheId] as Observable<T>;
            }
        }
        else {
            const cacheId = (prefixOrOpts ? prefixOrOpts : '') + id;
            return this.tempCache[cacheId] as T;
        }
    }

    getTempObjects<T>(ids: string[]): T[];
    getTempObjects<T>(ids: string[], opts: { cacheIdPrefix?: string }): T[];
    getTempObjects<T>(ids: string[], opts: { fn: (ids: string[]) => Observable<T[] | undefined>, cacheIdPrefix?: string, ttl?: number }): Observable<T[]>;
    getTempObjects<T>(
        ids: string[],
        opts?: {
            fn?: (ids: string[]) => Observable<T[] | undefined>,
            cacheIdPrefix?: string,
            ttl?: number
        }
    ): T[] | Observable<T[]> {
        const fn = opts?.fn;
        const cacheIdPrefix = opts?.cacheIdPrefix || '';
        const ttl = TEMP_CACHE_TTL - (opts?.ttl || 60) * 1000;
        const present: T[] = ids.filter(id => this.tempCache[cacheIdPrefix + id]).map(id => this.tempCache[cacheIdPrefix + id]);
        if (fn) {
            const missed: string[] = ids.filter(id => !this.tempCache[cacheIdPrefix + id]);
            if (missed.length > 0) {
                return new Observable<T[]>(obs => {
                    fn(missed).subscribe({
                        next: items => {
                            if (items) {
                                items.forEach(item => this.putTempObject(cacheIdPrefix + (item as any).id, item, ttl));
                            }
                            obs.next([...(items || []), ...present]);
                        },
                        error: err => obs.error(err),
                        complete: () => obs.complete()
                    })
                });
            }
            else {
                return of(present);
            }
        }
        else {
            return present;
        }
    }

    getLastActivity(): number {
        return this._lastActivity;
    }

    updateLastActivity(): void {
        // this._W('updateLastActivity');
        this._lastActivity = Date.now();
    }

    setLastLogin(login: string): void {
        this.setItem('lastLogin', login);
    }

    getLastLogin(): string {
        return this.getItem('lastLogin');
    }

    logout(title: string, text: string, data?: any): void {
        this.clearTempCache();
        this.authTokenState.next(AccessTokenState.Unknown);
        this.onLogout.next({ title, text, data });
    }

    isAppActive(): boolean {
        const ats = this.authTokenState.getValue();
        return ats == AccessTokenState.Good || ats == AccessTokenState.ExpiredRefreshing;
    }

    isLoggedIn(): boolean {
        const us = this.getStateDirect('user');
        return us.isLoggedIn() && !us.isGuest;
    }

    isAdmin(priv = '__admin'): boolean {
        if (!this.isLoggedIn()) {
            return false;
        }
        let granted: any = this.granted.getValue();
        if (environment.admin) {
            if (!granted || !granted.global || !Object.keys(granted.global).length) {
                if (this.user?.orgs && this.user?._isAdmin()) {
                    const gp = { __admin: true };
                    setGrantedPrivileges(this.user.orgs.map(pod => pod.privs || []).flat(), gp, p => !p.startsWith('admin'));
                    granted = { global: gp };
                    this.granted.next(granted);
                }
            }
        }
        this._L('isAdmin', 'granted:', granted);
        this._L('isAdmin', `granted.global[${priv}]:`, granted?.global[priv]);
        return !!granted?.global[priv];

    }

    updateGrantedPrivs(): void {
        const orgId = this.activeOrgId.getValue();
        const user = this.user;
        // this._L('updateGrantedPrivs', 'userId:', this.userId, '\n\t user:', user, '\n\t orgId:', orgId, '\n\t user.org:', orgId ? user?._orgMap[orgId] : undefined);
        if (this.userId && orgId && user?._orgMap[orgId]) {
            const org = user?._orgMap[orgId];
            const granted = getAllUserPrivileges(
                this.userId,
                org,
                org.groupIds,
                org.roleIds,
                this.getStateDirect('groups').items,
                this.getStateDirect('roles').items,
                this.getStateDirect('projects').items
            );
            if (user?._isAdmin()) {
                granted.global.__admin = true;
            }
            this._L('updateGrantedPrivs', granted);
            this.granted.next(granted);
        }
    }

}
