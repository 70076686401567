export function matchFilter(str: string | undefined, filter: string | undefined): boolean {
    if (filter == null) {
        return true;
    }
    if (str == null) {
        return false;
    }
    return str.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) > -1;
}

export function matchFilterNameOrId(p: { id?: string, name?: string } | undefined, filter: string | undefined): boolean {
    if (filter == null) {
        return true;
    }
    if (p == null) {
        return false;
    }
    if (filter.startsWith('#') && filter.length == 37 && p.id == filter.substring(1).toUpperCase()) {
        return true;
    }
    if (p.name && p.name.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) > -1) {
        return true;
    }
    return false;
}


export function matchFilterAny(strs: (string | undefined)[], filter: string | undefined): boolean {
    if (filter == null) {
        return true;
    }
    if (!strs || strs.length == 0) {
        return false;
    }
    for (const str of strs) {
        const match = str != null && str.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) > -1;
        if (match) {
            return true;
        }
    }
    return false;
}

export function cutString(s: string, l: number): string {
    return s == undefined ? '<undefined>' : (s.length > l ? s.substring(0, l) + '…' : s);
}

const proto = /^(?:\w+:)?\/\/(\S+)$/;
const lh = /^localhost[:?\d]*(?:[^:?\d]\S*)?$/
const nlh = /^[^\s.]+\.\S{2,}$/;
export function isUrl(url?: string): boolean {
    if (!url) {
        return false;
    }
    const m = url.match(proto);
    return !!m && !!m[1] && (lh.test(m[1]) || nlh.test(m[1]));
}

export function isNodeEmpty(node: any): boolean {
    if (!node) {
        return true;
    }
    if (node.type == 'emoji' || node.type == 'mention' || node.type == 'TemplateVariable') {
        return false;
    }
    if (node.type == 'text') {
        return !node.text?.trim();
    }
    else if (node.content?.length) {
        for (const n of node.content) {
            if (!isNodeEmpty(n)) {
                return false;
            }
        }
    }
    return true;
}

export function trimRichText(data: any): any {
    if (!data || data.type != 'doc' || !data.content?.length) {
        return;
    }
    let c = 0;
    for (const p of data.content) {
        if (!isNodeEmpty(p)) {
            break;
        }
        c++;
    }
    for (let i = 0; i < c; i++) {
        data.content.shift();
    }
    c = 0;
    for (let i = data.content.length - 1; i >= 0; i--) {
        if (!isNodeEmpty(data.content[i])) {
            break;
        }
        c++;
    }
    for (let i = 0; i < c; i++) {
        data.content.pop();
    }
    if (!data.content?.length) {
        return;
    }
    return data;
}

export function getSingleEmoji(rt: any): string | undefined {
    if (!rt) {
        return;
    }
    if (
        rt.type == 'doc' && rt.content && rt.content.length == 1
        && rt.content[0] && rt.content[0].type == 'paragraph' && rt.content[0].content && rt.content[0].content.length == 1
    ) {
        const em = rt.content[0].content[0];
        if (em?.type == 'emoji' && em?.attrs?.hexcode) {
            return em.attrs.hexcode;
        }
    }
    return;
}

export function getInitials(name?: string): string | undefined {
    name = name || '??';
    const na = name.replace(/[<|>|"|'|,|.|{|}|[|\]|(|)|\\|/|?|+|=|\-|_|~|`|!|@|#|$|%|^|&|*]/, '').split(/\s+/).filter(n => n.length > 0);
    if (na.length > 0) {
        let initials = '';
        if (na.length == 1) {
            initials = na[0].substring(0, 2);
        }
        else {
            for (const n of na) {
                if (n.length > 0) {
                    initials += n.substring(0, 1);
                    if (initials.length > 1) {
                        break;
                    }
                    else if (na.length == 1 && n.length > 1) {
                        initials += n.substring(1, 1);
                    }
                }
            }
        }
        return initials || undefined;
    }
    return undefined;
}

export function string2richText(s?: string): any {
    return {
        type: 'doc',
        content: (s || '').split('\n').filter(l => !!l).map(l => ({ type: 'paragraph', content: [{ type: 'text', text: l }] }))
    };
}

export function isInt(v?: string): boolean {
    return !isNaN(parseInt(v as any)) && !isNaN(Number(v));
}
