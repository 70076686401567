import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, HostBinding, Input, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';

import { BaseComponent } from '@base/base.component';
import {
    Group, IdName, IdNameAvatar, IdNameInitials, IntegrationSetup, ObjectTypeIcon,
    Org, Person, Project, SM, SYSTEM_USER, SYSTEM_USER_ID, StatesType,
    Tag, UserOnlineStatus, getInitials, string2Color
} from '@models';
import { InputBoolean, InputNumber, } from '@models/utils/coercions';
import { StoreService } from '@services';

@Component({
    selector: 'vz-avatar, [vz-avatar]',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
@Tag('AvatarComponent')
export class AvatarComponent extends BaseComponent implements OnChanges {
    _hostLayout = false;
    @HostBinding('style.border-radius')
    get _br(): string {
        return this.realShape == 'square' || this.withName ? '0' : (this.realShape == 'rounded' ? '7px' : '50%');
    }

    @Input() uid?: string;
    @Input() item?: Person | Org | Group | Project | IdName | IdNameAvatar | IdNameInitials | IntegrationSetup;
    @Input() @InputNumber() size: number | string = 32;
    @Input() type: 'user' | 'group' | 'org' | 'project' | 'integration' = 'user';
    @Input() shape?: 'square' | 'circle' | 'rounded';
    @Input() cursor: string = 'pointer';
    @Input() note?: string;
    @Input() @InputBoolean() showStatus?: boolean | string = false;
    @Input() @InputBoolean() withName?: boolean | string = false;
    @Input() @InputBoolean() asTag?: boolean | string = false;
    @Input() @InputBoolean() noTooltip?: boolean | string = false;
    @Input() @InputBoolean() noBorder?: boolean | string = false;
    @Input() @InputBoolean() isSystem?: boolean | string = false;
    @Input() @InputBoolean() withColor?: boolean | string = false;
    @Input() @InputBoolean() smallImg?: boolean | string = false;
    @Input() @InputBoolean() noImg?: boolean | string = false;
    @Input() @InputBoolean() forceActive?: boolean | string = false;
    @Input() fallbackName?: string;

    @ContentChild('prefix', { static: false }) prefixTpl?: TemplateRef<any>;

    stores: SM<keyof StatesType> = {
        user: 'persons',
        group: 'groups',
        org: 'orgs',
        project: 'projects',
        integration: 'integrations'
    };
    obj?: Person | Org | Group | Project | IdName | IdNameAvatar | IdNameInitials | IntegrationSetup;
    initials?: string;
    name?: string;
    url?: string;
    unknown: boolean = false;
    typeName: string = '';
    bgColor?: string;
    nameColor?: string;
    textColor?: string;
    fs: number = 14;
    sSize: number = 32;
    realShape?: 'square' | 'circle' | 'rounded';
    online?: UserOnlineStatus;
    suspended?: boolean;
    lastSeenDt?: number;
    disabled?: boolean;

    SUID = SYSTEM_USER_ID;
    UOS = UserOnlineStatus;

    constructor(protected _store: StoreService, private _cdr: ChangeDetectorRef) {
        super();
    }

    ngOnChanges(_changes: SimpleChanges): void {
        this.url = undefined;
        this.obj = undefined;
        this.name = undefined;
        this.initials = undefined;
        this.bgColor = undefined;
        this.typeName = '';
        this.lastSeenDt = undefined;
        this.unknown = (!this.item || this.item.id === '') && !this.uid;
        this.realShape = this.shape || (this.type == 'user' ? 'circle' : 'rounded');
        this.activeOrgId = this._store.activeOrgId.getValue();
        this.isGuest = this._store.getState('user').isGuest;
        this.updateAvatar();
        this.fs = Math.round(+this.size / 1.4) - 2;
        if (this.fs < 9) {
            this.fs = 9;
        }
        else if (this.fs > 16) {
            this.fs = 16;
        }
        this.sSize = this.fs * 1.3 + 1;
        this._cdr.markForCheck();
    }

    updateAvatar(): void {
        this.__unsubscribe();
        this.suspended = false;
        if (this.unknown) {
            this.url = undefined;
            this.setUnknown();
        }
        else if (this.item) {
            this.obj = this.item;
            this.update();
        }
        else if (this.uid) {
            if (this.uid == SYSTEM_USER_ID) {
                this.url = SYSTEM_USER.avatar;
                this.initials = '@';
                this.name = SYSTEM_USER.firstName;
                this.bgColor = '#0060AA';
            }
            else {
                this._S.avatar = this._store.state<any>(this.stores[this.type]).subscribe((st: any) => {
                    // this._L('updateAvatar', 'uid:', this.uid, 'obj:', this.obj, '\n\t st:', st);
                    if (this.uid && (st.modified[this.uid] || (!this.obj && st.items[this.uid]))) {
                        if (st.items[this.uid]) {
                            this.obj = st.items[this.uid];
                        }
                        this.update();
                        this._cdr.markForCheck();
                    }
                    else if (!this.obj) {
                        this.setUnknown();
                        this.update();
                        this._cdr.markForCheck();
                    }
                });
            }
        }
        else {
            this.setUnknown();
        }
        this.typeName = this.type == 'group'
            ? 'Группа: '
            : this.type == 'org'
                ? 'Организация: '
                : this.type == 'project'
                    ? 'Проект: '
                    : this.type == 'integration'
                        ? 'Интеграция: '
                        : '';
    }

    setUnknown(): void {
        if (this.fallbackName) {
            this.initials = ObjectTypeIcon[this.type == 'user' ? 'person' : (this.type == 'integration' ? 'integrationsetup' : this.type)] || '?';
            this.name = this.fallbackName;
            this.bgColor = string2Color(this.uid);
        }
        else {
            this.initials = this.type == 'user' ? 'vzi-person-o' : (this.type == 'integration' ? 'vzi-webhooks' : 'vzi-question');
            this.name = this.type == 'integration' ? 'Неизвестная интеграция' : 'Удаленный пользователь'; // ('#' + (this.uid || '?????').substring(0, 5) + '…');
            this.bgColor = undefined;
        }
    }

    update(): void {
        this.suspended = false;
        if (!this.obj) {
            return;
        }
        if (this.obj instanceof Person) {
            this.type = 'user';
            this.realShape = this.shape || 'circle';
        }
        else if (this.obj instanceof Group) {
            this.type = 'group';
            this.realShape = this.shape || 'rounded';
        }
        else if (this.obj instanceof Org) {
            this.type = 'org';
            this.realShape = this.shape || 'rounded';
            if (this.obj.id) {
                const org = this._store.getState('orgs').items[this.obj.id];
                this.suspended = org?.suspended;
            }
            else {
                this.suspended = false;
            }
        }
        else if (this.obj instanceof Project) {
            this.type = 'project';
            this.realShape = this.shape || 'rounded';
        }
        else if (this.obj instanceof IntegrationSetup) {
            this.type = 'integration';
            this.realShape = this.shape || 'rounded';
            this.noBorder= true;
        }
        this.url = (this.obj as IdNameAvatar).avatar;
        this.disabled = !this.forceActive && !this.isGuest && this.type == 'user' && (this.obj as any)._isActive && !(this.obj as any)._isActive(this.activeOrgId);
        this.name = (this.obj as any).name || (this.obj as any).title;
        this.nameColor = this.disabled ? '#888' : string2Color(this.obj.id);

        if ((this.obj as any).color) {
            this.bgColor = (this.obj as any).color;
        }
        else if (this.obj.id) {
            this.bgColor = string2Color(this.obj.id);
        }

        if ((this.obj as any).initials) {
            this.initials = (this.obj as any).initials;
            if (this.initials?.length && this.initials?.length > 2 && (this.obj as any).textColor) {
                this.textColor = (this.obj as any).textColor;
            }
        }
        else if (this.type == 'project') {
            this.initials = (this.obj as Project).abbr || '??';
        }

        if (!this.initials) {
            this.initials = getInitials(this.name);
        }
        this.url = !this.url ? undefined : this.url;
        if (this.showStatus && this.type == 'user' && this.obj.id != this.userId && !this.disabled)  {
            this._S.status = this._store.state('status').subscribe(st => {
                this.activeOrgId = this._store.activeOrgId.getValue();
                const status = this.obj && st.items[this.activeOrgId + '|' + this.obj?.id];
                this.online = status ? status.onlineStatus : UserOnlineStatus.Offline;
                this.lastSeenDt = status?.lastSeenDt;
                // console.log('-----------------> status:', status, 'online:', this.online);
                this._cdr.markForCheck();
            });
        }

    }

}
