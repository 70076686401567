import { BaseSystemModel, Guid, RecursivePartial, SM, VzSystemModule } from '@models/base';

export class IntegrationTrigger extends BaseSystemModel {
    integrationSetupId!: Guid;
    filter?: SM<any>;
    actionType!: VzSystemModule;
    actionData?: SM<any>;

    constructor(json?: RecursivePartial<IntegrationTrigger>) {
        super(json);
        this.parseIntegrationTrigger(json || {});
    }

    parse(json: RecursivePartial<IntegrationTrigger>): IntegrationTrigger {
        super.parse(json);
        this.parseIntegrationTrigger(json);
        return this;
    }

    private parseIntegrationTrigger(json: RecursivePartial<IntegrationTrigger>): void {
        IntegrationTrigger.assignFields(this, json, ['integrationSetupId', 'filter', 'actionType', 'actionData']);
    }

}
