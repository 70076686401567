import { BaseModel, ModelTemplate, RecursivePartial } from '@models/base';
import { VzParamStyles } from './param-styles';
import { VzParamConstraints } from './param-constraints';
import { VzParamTrigger } from './param-trigger';
import { VzParamTplvar } from './param-tplvar';

export enum VzParamType {
    Boolean      = 1,
    String       = 2,
    Integer      = 3,
    Text         = 4,
    ProjectId    = 5,
    GroupId      = 6,
    UIGroup      = 7,  // fake param for groupping other params
    // Enum         = 8,  // deprecated, set enumValues
    OrgId        = 9,
    User4AdminId = 10,
    IntRange     = 11,
    Date         = 12,
    UIAction     = 13, // fake param to provide some interaction, button for example
    Color        = 14,
    // JsonArray    = 15, // deprecated, use JsonObject
    JsonObject   = 16,
    ChatId       = 17,
    RichText     = 18,
    Array        = 19,
    Object       = 20,
}

export interface IVzParam extends ModelTemplate<VzParam> {}
export class VzParam extends BaseModel {
    static Type = VzParamType;
    id!: string;
    type!: VzParamType;
    name?: string;
    description?: string;
    defaultValue?: any;
    constraints?: VzParamConstraints;
    styles?: VzParamStyles;
    enumValues?: number[] | string[];
    enumNames?: string[];
    children?: VzParam[];
    triggers?: VzParamTrigger[];
    tplVars?: VzParamTplvar[];                                        // array of teplate variables for VzParamType.RichText
    isOutput?: boolean;

    _enumMap?: { [id: number | string]: string };
    _path?: string = '';

    constructor(json?: RecursivePartial<VzParam>) {
        super(json);
        this.parseTaskAction(json || {});
    }

    parse(json: RecursivePartial<VzParam>): VzParam {
        super.parse(json);
        this.parseTaskAction(json);
        return this;
    }

    private parseTaskAction(json: RecursivePartial<VzParam>): void {
        VzParam.assignFields<VzParam>(this, json, [
            'id',
            'type',
            'name',
            'description',
            'defaultValue',
            'enumValues',
            'enumNames',
            'isOutput',
            '_path'
        ]);
        VzParam.assignClassFields<Omit<VzParam, 'defaultValue'>>(this, json, {
            constraints: VzParamConstraints,
            styles: VzParamStyles
        });
        VzParam.assignClassArrays<Omit<VzParam, 'defaultValue'>>(this, json, {
            children: VzParam,
            triggers: VzParamTrigger,
            tplVars: VzParamTplvar
        }, { children: { _path: this._path ? this._path + '/' + this.id : this.id } });
        if (!this._enumMap && this.enumValues) {
            this._enumMap = {};
            for (let i = 0; i < this.enumValues.length; i++) {
                this._enumMap[this.enumValues[i]] = this.enumNames ? this.enumNames[i] : '' + this.enumValues[i];
            }
        }
    }

}
