export enum ObjectType {
    App = 'app',
    Org = 'org',
    Tag = 'tag',
    Chat = 'chat',
    File = 'file',
    Role = 'role',
    Task = 'task',
    Group = 'group',
    Action = 'action',
    Invite = 'invite',
    Person = 'person',
    Plugin = 'plugin',
    Contact = 'contact',
    Project = 'project',
    KanbanBoard = 'board',
    ChatMention = 'mention',
    Department = 'department',
    Invitation = 'invitation',
    ChatMessage = 'chatmessage',
    UserOrgStatus = 'orgstatus',
    Notification = 'notification',
    IncomingCall = 'incomingcall',
    CalendarEvent = 'calendarevent',
    UserOrgStatusType = 'orgstatustype',
    IntegrationSetup = 'integrationsetup',
}

export const ObjectTypeIcon: { [T in ObjectType]: string } = {
    [ObjectType.App]: 'vzi-site',
    [ObjectType.Org]: 'vzi-org',
    [ObjectType.Tag]: 'vzi-tag-lines',
    [ObjectType.Chat]: 'vzi-chat',
    [ObjectType.File]: 'vzi-attachment',
    [ObjectType.Role]: 'vzi-worker',
    [ObjectType.Task]: 'vzi-completed',
    [ObjectType.Group]: 'vzi-team',
    [ObjectType.Action]: 'vzi-note',
    [ObjectType.Invite]: 'vzi-user-add',
    [ObjectType.Person]: 'vzi-person-o',
    [ObjectType.Plugin]: 'vzi-yingyang',
    [ObjectType.Contact]: 'vzi-email',
    [ObjectType.Project]: 'vzi-project',
    [ObjectType.ChatMention]: 'vzi-comment-o',
    [ObjectType.Department]: 'vzi-house',
    [ObjectType.Invitation]: 'vzi-user-add',
    [ObjectType.ChatMessage]: 'vzi-comment-o',
    [ObjectType.KanbanBoard]: 'vzi-kanban',
    [ObjectType.Notification]: 'vzi-bell-o',
    [ObjectType.IncomingCall]: 'vzi-phone',
    [ObjectType.UserOrgStatus]: 'vzi-info',
    [ObjectType.CalendarEvent]: 'vzi-calendar1',
    [ObjectType.UserOrgStatusType]: 'vzi-info',
    [ObjectType.IntegrationSetup]: 'vzi-webhooks',
}

export const ObjectTypePath: { [T in ObjectType]: ((orgId: string, id: string) => string) | undefined } = {
    [ObjectType.App]: (orgId, id) => `/ui/${orgId}/settings/apps/${id}`,
    [ObjectType.Org]: (orgId, _id) => `/ui/${orgId}/settings/org`,
    [ObjectType.Tag]: (orgId, id) => `/ui/${orgId}/settings/tags/${id}`,
    [ObjectType.Chat]: (orgId, id) => `/ui/${orgId}/chats/${id}`,
    [ObjectType.File]: undefined,
    [ObjectType.Role]: (orgId, id) => `/ui/${orgId}/settings/roles/${id}`,
    [ObjectType.Task]: (orgId, id) => `/ui/${orgId}/tasks/${id}`,
    [ObjectType.Group]: (orgId, id) => `/ui/${orgId}/settings/groups/${id}`,
    [ObjectType.Action]: undefined,
    [ObjectType.Invite]: (orgId, id) => `/ui/${orgId}/settings/invites/${id}`,
    [ObjectType.Person]: (orgId, id) => `/ui/${orgId}/employees/${id}`,
    [ObjectType.Plugin]: (orgId, id) => `/ui/${orgId}/settings/plugins/${id}`,
    [ObjectType.Contact]: undefined,
    [ObjectType.Project]: (orgId, id) => `/ui/${orgId}/projects/${id}`,
    [ObjectType.ChatMention]: undefined,
    [ObjectType.Department]: (orgId, id) => `/ui/${orgId}/settings/deps/${id}`,
    [ObjectType.Invitation]: (orgId, _id) => `/ui/${orgId}/settings/profile`,
    [ObjectType.ChatMessage]: undefined,
    [ObjectType.KanbanBoard]: (orgId, id) => `/ui/${orgId}/tasks?list=kb-${id}`,
    [ObjectType.Notification]: undefined,
    [ObjectType.IncomingCall]: undefined,
    [ObjectType.UserOrgStatus]: undefined,
    [ObjectType.CalendarEvent]: (orgId, id) => `/ui/${orgId}/events/${id}`,
    [ObjectType.UserOrgStatusType]: (orgId, id) => `/ui/${orgId}/settings/statuses/${id}`,
    [ObjectType.IntegrationSetup]: (orgId, id) => `/ui/${orgId}/settings/integrations/${id}`,
}
