import { BaseModel, RecursivePartial } from '@models/base';

export class VzParamTplvar extends BaseModel {
    id?: string;
    name?: string;
    refId?: string;

    constructor(json?: RecursivePartial<VzParamTplvar>) {
        super(json);
        this.parseVzParamTplvar(json || {});
    }

    parse(json: RecursivePartial<VzParamTplvar>): VzParamTplvar {
        super.parse(json);
        this.parseVzParamTplvar(json);
        return this;
    }

    private parseVzParamTplvar(json: RecursivePartial<VzParamTplvar>): void {
        VzParamTplvar.assignFields<VzParamTplvar>(this, json, ['id', 'name', 'refId']);
    }
}
