import { BaseSystemModel, Guid, RecursivePartial, SM } from '@models/base';
import { VzParam } from '@models/params';

export class IntegrationSetup extends BaseSystemModel {
    integrationId!: Guid;
    orgId!: Guid;
    projectId!: Guid;
    groupId!: Guid;
    title?: string;
    avatar?: string;
    paramValues?: SM<any>;
    filters?: VzParam[];

    constructor(json?: RecursivePartial<IntegrationSetup>) {
        super(json);
        this.parseIntegrationSetup(json || {});
    }

    parse(json: RecursivePartial<IntegrationSetup>): IntegrationSetup {
        super.parse(json);
        this.parseIntegrationSetup(json);
        return this;
    }

    private parseIntegrationSetup(json: RecursivePartial<IntegrationSetup>): void {
        IntegrationSetup.assignFields(this, json, ['integrationId', 'orgId', 'projectId', 'groupId', 'title', 'avatar', 'paramValues']);
        IntegrationSetup.assignClassArrays(this, json, { filters: VzParam });
    }

}
