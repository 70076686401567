import { BaseSystemModel, ObjectType, RecursivePartial } from '@models/base';
import { Reaction } from '@models/shared/reaction';
import { ChatCall } from './chat-call';
import { ChatEvent } from './chat-event';
import { ChatMessageThread } from './chat-message-thread';
import { CodeSnippet } from '@models/shared/code-snippet';

export enum ChatMessageOrigin {
    InvalidOrigin   = 0,        // invalid origin
    System          = 1,        // initiator is a system
    Guest           = 2,        // initiator is a guest
    User            = 3,        // initiator is a system user
    Plugin          = 4,        // plugin is initiator
    Integration     = 5,        // integration is initiator, in that case created_by of the message is ID of integration
}

export class ChatMessage extends BaseSystemModel {
    _type: ObjectType.ChatMessage = ObjectType.ChatMessage;
    origin!: ChatMessageOrigin;
    chatId?: string;
    parentId?: string;
    replyId?: string;
    forwardId?: string;
    thread?: ChatMessageThread;
    deleted?: boolean;
    threadDepth: number = 0;
    text?: string;
    richText?: { [key: string]: any };
    call?: ChatCall;
    event?: ChatEvent;
    fileIds?: string[];
    snippets?: CodeSnippet[];
    reactions?: Reaction[];
    numId: number = 0;

    _isHuman?: boolean;
    _hasAvatar?: boolean;

    constructor(json?: RecursivePartial<ChatMessage>) {
        super(json);
        this.parseChatMessage(json || {});
    }

    parse(json: RecursivePartial<ChatMessage>): ChatMessage {
        super.parse(json);
        this.parseChatMessage(json);
        return this;
    }

    private parseChatMessage(json: RecursivePartial<ChatMessage>): void {
        ChatMessage.assignFields<ChatMessage>(this, json, [
            'origin',
            'chatId',
            'parentId',
            'replyId',
            'forwardId',
            'deleted',
            'threadDepth',
            'text',
            'richText',
            'fileIds',
            'numId',
            '_isHuman'
        ]);
        ChatMessage.assignClassFields<ChatMessage>(this, json, { thread: ChatMessageThread, call: ChatCall, event: ChatEvent });
        ChatMessage.assignClassArrays<ChatMessage>(this, json, { snippets: CodeSnippet, reactions: Reaction });
        this._isHuman = this.origin == ChatMessageOrigin.User || this.origin == ChatMessageOrigin.Guest;
        this._hasAvatar = this._isHuman || this.origin == ChatMessageOrigin.Integration;
        if (this.call) {
            this.call.chatId = this.chatId;
        }
    }

}
