import { BaseModel, Guid, RecursivePartial } from '@models/base';
import { Task } from './task';

export class ProjectInprogressStats extends BaseModel {
    userId?: Guid;
    tasks?: Task[];

    constructor(json?: RecursivePartial<ProjectInprogressStats>) {
        super(json);
        this.parseProjectInprogressStats(json || {});
    }

    parse(json: RecursivePartial<ProjectInprogressStats>): ProjectInprogressStats {
        super.parse(json);
        this.parseProjectInprogressStats(json);
        return this;
    }

    private parseProjectInprogressStats(json: RecursivePartial<ProjectInprogressStats>): void {
        ProjectInprogressStats.assignFields(this, json, ['userId']);
        ProjectInprogressStats.assignClassArrays(this, json, { tasks: Task });
    }

}
