import { BaseModel, RecursivePartial } from '@models/base';

export class ProjectTaskStats extends BaseModel {
    created: number = 0;
    createdExp: number = 0;
    inprogress: number = 0;
    inprogressExp: number = 0;
    done: number = 0;

    constructor(json?: RecursivePartial<ProjectTaskStats>) {
        super(json);
        this.parseProjectTaskStats(json || {});
    }

    parse(json: RecursivePartial<ProjectTaskStats>): ProjectTaskStats {
        super.parse(json);
        this.parseProjectTaskStats(json);
        return this;
    }

    private parseProjectTaskStats(json: RecursivePartial<ProjectTaskStats>): void {
        ProjectTaskStats.assignFields(this, json, [
            'created',
            'createdExp',
            'inprogress',
            'inprogressExp',
            'done',
        ]);
    }

}
